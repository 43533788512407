@import "variables";
.comparador-sidebar {
  position: sticky;
  top: 150px;
  padding: 20px;
  padding-top: 50px;
  font-size: 0.9em;
  label {
    color: white !important;
  }
  .form-control {
    border: none;
    border-bottom: white 2px solid;
    border-radius: 0px;
    background: none;
    color: white;
  }
  .close {
    color: white;
  }
  .dropdown-menu {
    background: $dark-blue;
    a {
      font-size: 0.9em;
      color: white !important;

      &:hover {
        color: $dark-blue !important;
      }
    }
    transition: all 0.3s ease-in-out;
  }
  .compare-btn {
    border: white 1px solid;
    background-color: $dark-blue;
  }
}

.comparador-card {
  height: 100%;
  border: none;
  .card-header {
    border-radius: 0px;
    background-color: $dark-blue;
    color: white;
  }
  .card-body {
    padding: 0px;

    .btn-group {
      position: sticky;
      top: 0px;
      height: 50px;
      width: 100%;
      background: $dark-blue;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-end;
      .btn {
        font-size: 12px !important;
        width: calc(100% / 10);
        height: 100%;
        background-color: $dark-blue;
        border: none;
        &:hover {
          background: white;
          color: $dark-blue;
        }
        &.active {
          background-color: $medium-blue;
        }
      }
    }
    .compare-table-wrapper {
      overflow-y: scroll;
      max-height: 75vh;
    }
  }
}
