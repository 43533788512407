@import url("https://maxcdn.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css");
@import "comparador";
@import "cotizador";
@import "files";
@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf");
}
@import "variables";
* {
  font-family: Lato;
}
body,
html {
  width: 100%;
  font-family: Lato;
  height: 100%;
}
#root {
  height: 100%;
}
p {
  margin-bottom: 0px;
}

.basic-layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content-wrapper {
    flex-grow: 1;
  }
  .sidebarView {
    display: flex;
    flex-direction: row;
    height: 100%;
    .sidebar {
      background-color: $dark-blue;
      flex-direction: column;
      overflow: none;

      .cloud-sidebar {
        display: flex;
        padding: 20px;
        margin-top: -50px;
        flex-direction: column;
        justify-content: center;
        height: 100%;
      }
    }
  }

  #navbar {
    border-bottom: blue 1px solid;
    .navbar-brand {
      width: 10%;
      padding: 3px;
      img {
        margin-top: 5px;
        margin-left: 20px;
        width: 100%;
      }
    }
  }
  #home-screen {
    flex-grow: 10;
  }
}
.homepage {
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: 100%;
}

.feather {
  display: flex;
  align-items: center;
  justify-content: center;
  border: $medium-blue 2px solid;
  width: calc(100vw / 8);
  height: calc(100vw / 8);
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: $medium-blue;
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    background: $medium-blue;
    color: white;
  }
}

.custom-input {
  width: 100%;
  border-radius: 18px;
  padding: 10px;
  border: $medium-blue 2px solid;
  outline: none;
  padding-left: 5%;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover {
    box-shadow: 2px 2px 10px lightblue;
  }
}

.btn-round {
  border-radius: 25px;
  background: $dark-blue;
  &:hover {
    background: $dark-gray;
    border: $dark-gray 1px solid;
  }
}

.rate-compare-table {
  font-family: Arial, Helvetica, sans-serif !important;
  border-collapse: initial;
  border-spacing: 0px 5px;
  th,
  td,
  tr {
    border: none !important;
  }
  th {
    width: 25%;
    padding: 0px;
  }
  .compare-table-header {
    background: #4a69f9;
    margin: 5px;
    p {
      font-family: Arial, Helvetica, sans-serif;
    }
    .company-name {
      font-size: 1.1em;
      font-weight: bold;
    }

    border-radius: 10px;
    padding: 10px;
    text-align: center;
    color: white;
    font-size: 0.7em;
  }
  .deductible-div {
    margin: 5px;
    background: #4a69f9;
    padding: 8px;
    border-radius: 8px;
    font-size: 0.7em;
    text-align: center;

    color: white;
  }
  .beneficiary-row {
    background-color: rgb(239, 245, 255);

    th,
    td {
      margin-top: 5px;
      margin-bottom: 5px;
      padding: 5px;
      font-size: 0.8em;
    }
    td {
      text-align: center;
    }
  }
  .footer-row {
    th,
    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .prima-cell {
    font-size: 0.8em;
    background: $dark-blue;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    color: white;
    font-weight: bold;
  }
}

.compare-list-group {
  background: none;
  margin-bottom: 25px;
  margin-top: 15px;
  .list-group-item {
    border: $light-blue 1px solid;
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    text-indent: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    .fa-trash {
      cursor: pointer;
    }
  }
}

.login-screen {
  background: $medium-blue;
  .form-wrapper {
    background: white;
    border-radius: 15px;
    padding: 25px;
    box-shadow: 2px 2px 10px $light-blue;
    color: $dark-gray;
    input {
      border: none;
      border-bottom: $dark-blue 2px solid;
      border-radius: 0px;
      box-shadow: none;
      text-align: left !important;
      padding: 0px;
      &:hover {
        inset: none;
        box-shadow: none;
        outline: none;
      }
    }
    .logo-wrap {
      margin-bottom: 30px;
      img {
        width: 30%;
      }
    }
  }
}
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $light-blue;
  font-size: 2em;
  z-index: 999;
}
.benefits-table {
  font-size: 0.9em;

  td {
    text-align: justify;
  }
}

.link {
  &:hover {
    text-decoration: none;
  }
  text-decoration: none;
}
